@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100vw;
  height: 3.5rem;
  background-color: rgba(36, 36, 36, 0.6);
  position: fixed;
  z-index: 50;
  top: 0px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  margin: 0px;
}

nav a {
  text-decoration: none;
  color: #39f7d2;
  text-transform: uppercase;
  font-size: 1rem;
  transition: all 400ms;
}

nav a:hover {
  color: white;
}

.materialIcons {
  font-family: 'Material Icons';
  font-size: 3.2rem;
}

#about {
  height: 34.5rem;
  width: 100%;
  position: relative;
  background-color: #242424;
  color: #39f7d2;
  background-image: url('/img/header.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#about .container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

#about h1 {
  font-size: 3.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 1rem;
}

#about h3 {
  font-weight: 400;
  font-size: 1rem;
  margin: 0px;
  text-transform: uppercase;
}

#work {
  min-height: 40rem;
  width: 100%;
  background-color: #39f7d2;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 4rem;
}

.button {
  display: inline-block;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  border: 4px solid white;
  padding: 0.25rem 1rem;
  margin: 2rem;
}

#work .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#work .project {
  width: 25rem;
  height: 18rem;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
  margin: 1.5rem;
}

#work a {
  text-decoration: none;
}

.project .img-container {
  height: 70%;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  flex-grow: 1;
}

.project img {
  width: 100%;
}

.project .description {
  height: 30%;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: grey;
  z-index: 100;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
}

.project p {
  font-size: 0.9rem;
}

.project .description .title {
  font-size: 1.1rem;
  font-weight: 700;
  display: block;
}

#reactUi .thumbnail {
  background-image: url('/img/React-Animate-UI.gif');
  background-size: cover;
}

#hangry .thumbnail {
  background-image: url('/img/Hangry.gif');
  background-size: cover;
}

#blockchain .thumbnail {
  background-image: url('/img/blockchain.png');
  background-size: cover;
}

#knave .thumbnail {
  background-image: url('/img/Knave.png');
  background-size: cover;
}

#contact {
  height: 8rem;
  width: 100%;
  background-color: #242424;
  color: #39f7d2;
}

#contact a img {
  width: 1.75rem;
}

#contact .content {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 55%;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#contact p {
  font-size: 0.8rem;
}
